body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
}

@media all and (min-width: 992px) {
  .sidebar .nav>li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
  }
}

.card.card-upgrade .card-category {
  max-width: 530px;
  margin: 0 auto;
}

/* Nucleo Style */

.demo-iconshtml {
  font-size: 62.5%;
}

.demo-icons body {
  font-size: 1.6rem;
  font-family: sans-serif;
  color: #333333;
  background: white;
}

.demo-icons a {
  color: #608CEE;
  text-decoration: none;
}

.demo-icons header {
  text-align: center;
  padding: 100px 0 0;
}

.demo-icons header h1 {
  font-size: 2.8rem;
}

.demo-icons header p {
  font-size: 1.4rem;
  margin-top: 1em;
}

.demo-icons header a:hover {
  text-decoration: underline;
}

.demo-icons .nc-icon {
  font-size: 34px;
}

.demo-icons section h2 {
  border-bottom: 1px solid #e2e2e2;
  padding: 0 0 1em .2em;
  margin-bottom: 1em;
}

.demo-icons ul {
  padding-left: 0;
}

.demo-icons ul::after {
  clear: both;
  content: "";
  display: table;
}

.demo-icons ul li {
  width: 20%;
  float: left;
  padding: 16px 0;
  text-align: center;
  border-radius: .25em;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.demo-icons ul li:hover {
  background: #f4f4f4;
}

.demo-icons ul p,
.demo-icons ul em,
.demo-icons ul input {
  display: inline-block;
  font-size: 1rem;
  color: #999999;
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.demo-icons ul p {
  padding: 20px 0 0;
  font-size: 12px;
  margin: 0;
}

.demo-icons ul p::selection,
.demo-icons ul em::selection {
  background: #608CEE;
  color: #efefef;
}

.demo-icons ul em {
  font-size: 12px;
}

.demo-icons ul em::before {
  content: '[';
}

.demo-icons ul em::after {
  content: ']';
}

.demo-icons ul input {
  text-align: center;
  background: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: none;
}

.icon-lg{
  font-size: 20px !important;
}


/* nav tab */

.tabs-navigate{
  padding: 10px;
}

.tabs-navigate li{
  padding: 10px;
}

.tabs-navigate .active{
  background: #ef8157;
  color: white !important;
  padding: 10px;
}

.tabs-navigate a{
  padding: 10px;
}


.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.label-default{
  background-color: #66615B;
}

.label-almacen{
  background-color: #39adf1;
}

.label-credito{
  background-color: #ff9800;
}

.label-contabilidad{
  background-color: #4caf50;
}


.label-facturacion{
  background-color: #ff5722;
}

.label-ventas{
  background-color: #673ab7;
}

.icon-observaciones{
  font-size: 20px;
}

.rdt_TableBody{
  overflow-y: hidden !important
}

.btn{

}

@media only screen and (max-width: 1760px) {
  .btn{
    height: 2.2rem !important
  }
}

.react-datetime-picker {
  display: block;
  height: 100%;
}

.react-datetime-picker__wrapper {
  border: none !important;
}

.c-padding{
  padding: 6px !important;
}

.minibutton{
  background: none !important;
  color: #000 !important;
  font-size: 1rem !important;
}

.time {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: 'Source Sans Pro', arial, sans-serif;
  font-weight: 300;
  color: #333;
  box-sizing: border-box;
}

.time * {
    box-sizing: border-box;
}

.timeline {
  background:#fff;
  padding: 2rem 1.5rem;
  position: relative;
    width:100%;
}

.timeline:before {
    content: '';
    position: absolute;
    top: 0px;
    left:calc(40% + 6px);
    bottom: 0px;
    width: 4px;
    background: #ddd;
    margin-bottom: 7.8rem;
    margin-top: 2.6rem;
  }

  .timeline:after {
    content: "";
    display: table;
    clear: both;
  }

.entry {
  clear: both;
  text-align: left;
  position: relative;
}


.entry .title {
    margin-bottom: .5em;
    float: left;
    width: 40%;
    padding-right: 30px;
    text-align: right;
    position: relative;
  }

.entry .title:before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      border: 4px solid salmon;
      background-color:#fff;
      border-radius:100%;
      top: 15%;
      right: -10px;
      z-index: 99;
}

.entry .title h3 {
      margin: 0;
      font-size: 120%;
    }

.entry .title p {
      margin: 0;
font-size: 100%;
    } 


.entry .body {
    margin: 0 0 1em;
    float: right;
    width: 60%;
    padding-left: 30px;
  }

.entry .body p {
      line-height: 1.4em;
}

.entry .body p:first-child {
        margin-top: 0;
        font-weight: 400;
      }

.entry .body ul {
      color:#aaa;
      padding-left: 0;
      list-style-type: none;
    }

.entry .body ul li:before {
        content: "–";
        margin-right: .5em;
      }


@media (min-width: 576px){
  .modal-dialog {
      max-width: 72vh !important;
  }
}

@media (min-width: 576px){
  .lista-facturas .modal-dialog {
      max-width: 85vh !important;
  }
}

.nc-icon{
  font-size: 1.5rem !important;
}

.button-center{
  display: flex;
  align-items: center;
  height: 100%;
}